import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Produktion und Sicherheit Ihrer Systeme im Fokus!`}</p>
    <p>{`Um ganze Produktionsprozesse oder einzelne Produktionsschritte zu automatisieren, verbinden wir Maschinen und Produktionsanlagen mit Datenbanken und Serversystemen.`}</p>
    <h2>{`Wir bieten:`}</h2>
    <ul>
      <li parentName="ul">{`Definition von Fertigungsabläufen für konkrete Produkte`}</li>
      <li parentName="ul">{`Bereitstellung von Daten für bestimmte Produktionsschritte und Überwachung der Produktion`}</li>
      <li parentName="ul">{`Integration von Schnittstellen zur Materialwirtschaft und zur kaufmännischen Abwicklung`}</li>
      <li parentName="ul">{`Integration manueller und automatisierter Qualitätssicherungssysteme`}</li>
      <li parentName="ul">{`Bereitstellung von Produktdaten und Produktionsinformationen für statistische Auswertungen`}</li>
      <li parentName="ul">{`Einsatz spezieller Sicherheitsmechanismen zum Schutz von Vertraulichkeit und Integrität der Produktionsdaten`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      